import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'gatsby'
import styles from '~styles/components/banner.module.scss'

export default class Banner extends Component {
  getBackLink () {
    const location = this.props.location

    if (location && location.pathname) {
      const path = location.pathname.split('/') || []

      const levels = path.filter((item) => {
        return item !== ''
      }).slice(0, -1)

      if (levels.length === 0) {
        return '/'
      }

      return `/${levels.join('/')}`
    }

    return '/'
  }

  convertLinkToTitle (link) {
    if (link && link !== '/') {
      const paths = link.split('/')

      const parts = paths.filter((item) => {
        return item !== ''
      })

      if (parts.length > 0) {
        const name = parts[parts.length - 1]
        const title = name.replace(/-/g, ' ')
        return `to ${title.toLowerCase()}`
      }
    }

    return 'home'
  }

  render () {
    const { image, title, subtitle } = this.props
    const backLink = this.getBackLink()
    const backTitle = this.convertLinkToTitle(backLink)

    return (
      <div className={classNames(styles.banner, { [styles.hasSidebar]: this.props.sidebar }, 'section section-banner')}>
        <div className={classNames(styles.inner, 'grid-container')}>
          <div className={classNames(styles.content)}>
            <Link to={backLink} className={classNames(styles.pretitle)}>
              {`Back ${backTitle}`}
            </Link>

            <h1 className={classNames(styles.title, { [styles.titleCenter]: !subtitle })}>
              {title}
            </h1>

            {subtitle && (
              <span className={styles.subtitle}>
                {subtitle}
              </span>
            )}
          </div>

          {image && (
            <picture>
              <source srcSet={image.desktop.srcSetWebp} type="image/webp" media="(min-width: 79.6875em)" />
              <source srcSet={image.desktop.srcSet} type="image/jpeg" media="(min-width: 79.6875em)" />
              <source srcSet={image.tablet.srcSetWebp} type="image/webp" media="(min-width: 43.125em)" />
              <source srcSet={image.tablet.srcSet} type="image/jpeg" media="(min-width: 43.125em)" />
              <img src={image.thumb.src} srcSet={image.thumb.srcSet} alt={image.title} className={styles.image} />
            </picture>
          )}
        </div>
      </div>
    )
  }
}

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
  subtitle: PropTypes.string,
  location: PropTypes.object,
  sidebar: PropTypes.bool
}
