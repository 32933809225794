import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Head from '~components/Head'
import Banner from '~components/Banner'
import { use as useSection } from '../components/sections'

export default class Page extends Component {
  render () {
    const { title, subtitle, featuredImage, sections, metaDescription } = this.props.data.contentfulPage
    let position = 0

    return (
      <>
        <Head
          title={title}
          description={metaDescription}
          image={featuredImage}
          location={this.props.location}
        />

        <Banner
          title={title}
          subtitle={subtitle}
          image={featuredImage}
          location={this.props.location}
        />

        {sections && sections.length > 0 && sections.map((section, index) => {
          const Component = useSection(section.__typename)

          if (section.__typename === 'ContentfulSectionSignpost') {
            section.position = position
            ++position
          } else {
            position = index
          }

          return Component ? <Component key={index} data={section} position={position} /> : null
        })}
      </>
    )
  }
}

Page.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
}

export const query = graphql`
  query($id: String!) {
    contentfulPage(id: {eq: $id}) {
      title
      subtitle
      featuredImage {
        id
        title
        desktop: fixed(width: 1600, height: 550, quality: 80) {
          src
          srcSet
          srcSetWebp
        }
        tablet: fixed(width: 1100, height: 378, quality: 80) {
          src
          srcSet
          srcSetWebp
        }
        thumb: fixed(width: 414, height: 568, quality: 80) {
          src
          srcSet
          srcSetWebp
        }
        openGraph: fixed(width: 1200, height: 627, quality: 80, resizingBehavior: CROP, cropFocus: CENTER) {
          src
        }
      }
      sections {
        ... on ContentfulSectionMedia {
          ...Media
        }
        ... on ContentfulSectionAccordion {
          ...Accordion
        }
        ... on ContentfulSectionSignpost {
          ...Signpost
        }
        ... on ContentfulSectionSingleImage {
          ...SingleImage
        }
        ... on ContentfulSectionUsPs {
          ...UsPs
        }
        ... on ContentfulSectionTable {
          ...Table
        }
        ... on ContentfulSectionSupportingText {
          ...SupportingText
        }
        ... on ContentfulSectionTabs {
          ...Tabs
        }
        ... on ContentfulSectionCta {
          ...Cta
        }
        ... on ContentfulSectionText {
          ...Text
        }
      }
      metaDescription
    }
  }
`
